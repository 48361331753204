<template>
  <div id="content">
    <Breadcrumb :breadList="breadList">
    </Breadcrumb>
    <!--  -->
    <a-form-model
        ref="form"
        :model="form"
        :rules="rules"
        layout="horizontal"
        :label-col="{span:6}"
        :wrapper-col="{span:14}"
      >
        <br/>
          <a-form-model-item label="宣传奖励" prop="publicityRadio">
            <a-input-number v-model="form.publicityRadio" style="width:200px;" />
            <span> % </span>
          </a-form-model-item>
          <a-form-model-item label="销售奖励" prop="saleRadio">
            <a-input-number v-model="form.saleRadio" style="width:200px;" />
            <span> % </span>
          </a-form-model-item>
          <a-form-model-item label="服务奖励" prop="serviceRadio">
            <a-input-number v-model="form.serviceRadio" style="width:200px;" />
            <span> % </span>
          </a-form-model-item>
          <a-form-model-item label="提货奖励" prop="fetchRadio">
            <a-input-number v-model="form.fetchRadio" style="width:200px;" />
            <span> % </span>
          </a-form-model-item>
        <br/>
        <br/>
        <a-form-model-item label=" " :colon="false">
          <a-button type="primary" @click="onSubmit">
            保存
          </a-button>
        </a-form-model-item>
    </a-form-model>
    </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import floatObj from '@/utils/floatObj.js'
export default {
  components: {
    Breadcrumb
  },
  data () {
    const validInteger = (rule, value, callback) => {
      var value1 = floatObj.floatObj.divide(value, 100)
      if (value1) {
        if (!Number(value1)) {
          return callback(new Error('请输入数值'))
        } else {
          if (value1 < 0 || value1 >= 1) {
            return callback(new Error('请输入[0,100]之间的数字'))
          }
          var pattern = /^\d+.?\d{0,4}$/
          if (!pattern.test(value1)) {
            return callback(new Error('小数点后最多只能输入两位小数'))
          }
        }
      }
      callback()
    }
    return {
      visible: false,
      breadList: [
        {
          name: '驿站奖励设置',
          url: ''
        }
      ],
      form: {
        publicityRadio: null,
        saleRadio: null,
        serviceRadio: null,
        fetchRadio: null
      },
      rules: {
        publicityRadio: [
          { required: true, message: '请输入宣传奖励', trigger: 'blur' },
          { validator: validInteger, trigger: 'blur' }
        ],
        saleRadio: [
          { required: true, message: '请输入销售奖励', trigger: 'blur' },
          { validator: validInteger, trigger: 'blur' }
        ],
        serviceRadio: [
          { required: true, message: '请输入服务奖励', trigger: 'blur' },
          { validator: validInteger, trigger: 'blur' }
        ],
        fetchRadio: [
          { required: true, message: '请输入提货奖励', trigger: 'blur' },
          { validator: validInteger, trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getStageAward()
  },
  computed: {
  },
  methods: {
    async getStageAward () {
      const res = await this.$store.dispatch('http', {
        api: 'getStageAward'
      })
      if (res) {
        this.form = {
          publicityRadio: floatObj.floatObj.multiply(res.publicityRadio, 100),
          saleRadio: floatObj.floatObj.multiply(res.saleRadio, 100),
          serviceRadio: floatObj.floatObj.multiply(res.serviceRadio, 100),
          fetchRadio: floatObj.floatObj.multiply(res.fetchRadio, 100)
        }
      }
    },
    onSubmit () {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          const params = {
            publicityRadio: floatObj.floatObj.divide(this.form.publicityRadio, 100),
            saleRadio: floatObj.floatObj.divide(this.form.saleRadio, 100),
            serviceRadio: floatObj.floatObj.divide(this.form.serviceRadio, 100),
            fetchRadio: floatObj.floatObj.divide(this.form.fetchRadio, 100)
          }
          await this.$store.dispatch('http', {
            api: 'saveStageAward',
            params,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', { message: '保存成功~' })
          this.getStageAward()
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .form__ct {
    padding: 10px 0;
  }
  .form__items {
    padding-top: 30px;
  }
}
</style>
